import React, { FC } from "react";

const Stars: FC<{ score: number }> = ({ score }) => (
  <div className="flex items-center justify-between">
    {[...new Array(5)].map((e, i) => (
      <div key={i}>
        <StarIcon color={score > i ? "#ECC94B" : "#cbd5e0"} />
      </div>
    ))}
  </div>
);

const StarIcon: FC<{ color: string }> = ({ color }) => (
  <svg viewBox="0 0 100 100" version="1.1" x="0px" y="0px" width="100%">
    <path
      d="M50,13.82l8.318,23.813c0.328,0.939 0.936,1.756 1.741,2.342c0.805,0.585 1.77,0.91 2.765,0.932l25.218,0.552l-20.077,15.27c-0.792,0.602 -1.382,1.432 -1.689,2.379c-0.308,0.947 -0.319,1.965 -0.032,2.918l7.267,24.154l-20.726,-14.376c-0.818,-0.567 -1.79,-0.871 -2.785,-0.871c-0.995,0 -1.967,0.304 -2.785,0.871l-20.726,14.376l7.267,-24.154c0.287,-0.953 0.276,-1.971 -0.032,-2.918c-0.307,-0.947 -0.897,-1.777 -1.689,-2.379l-20.077,-15.27l25.218,-0.552c0.995,-0.022 1.96,-0.347 2.765,-0.932c0.805,-0.586 1.413,-1.403 1.741,-2.342l8.318,-23.813Z"
      fill="none"
    />
    <clipPath id="a">
      <path d="M50,13.82l8.318,23.813c0.328,0.939 0.936,1.756 1.741,2.342c0.805,0.585 1.77,0.91 2.765,0.932l25.218,0.552l-20.077,15.27c-0.792,0.602 -1.382,1.432 -1.689,2.379c-0.308,0.947 -0.319,1.965 -0.032,2.918l7.267,24.154l-20.726,-14.376c-0.818,-0.567 -1.79,-0.871 -2.785,-0.871c-0.995,0 -1.967,0.304 -2.785,0.871l-20.726,14.376l7.267,-24.154c0.287,-0.953 0.276,-1.971 -0.032,-2.918c-0.307,-0.947 -0.897,-1.777 -1.689,-2.379l-20.077,-15.27l25.218,-0.552c0.995,-0.022 1.96,-0.347 2.765,-0.932c0.805,-0.586 1.413,-1.403 1.741,-2.342l8.318,-23.813Z" />
    </clipPath>
    <g clipPath="url(#a)">
      <rect fill={color} x="-3.206" y="5.556" width="99.306" height="88.333" />
    </g>
    <path
      fill={color}
      d="M50.401,11.352c1.321,0.288 1.47,0.453 1.959,1.643c2.783,7.967 5.341,16.015 8.35,23.899c0.353,0.873 1.226,1.483 2.181,1.514l25.206,0.552c2.135,0.161 3.163,3.042 1.459,4.489c-6.717,5.108 -13.581,10.028 -20.149,15.326c-0.722,0.605 -1.032,1.625 -0.766,2.542l7.264,24.143c0.501,2.06 -1.867,3.978 -3.818,2.775c-6.934,-4.81 -13.734,-9.818 -20.803,-14.427c-0.798,-0.499 -1.864,-0.479 -2.654,0.057l-20.717,14.37c-1.822,1.124 -4.347,-0.603 -3.818,-2.775c2.431,-8.08 5.092,-16.095 7.292,-24.242c0.228,-0.914 -0.12,-1.921 -0.875,-2.507l-20.068,-15.262c-1.596,-1.356 -0.888,-4.312 1.459,-4.489c8.436,-0.185 16.881,-0.131 25.309,-0.557c0.94,-0.065 1.79,-0.707 2.114,-1.606l8.314,-23.802c0.442,-1.076 1.083,-1.824 2.761,-1.643Zm-6.363,27.116c-1.015,2.82 -3.789,4.843 -6.796,4.938l-18.074,0.396l14.39,10.944c0.486,0.377 0.518,0.426 0.731,0.638c1.883,1.875 2.614,4.771 1.864,7.351l-5.208,17.312l14.855,-10.304c0.509,-0.345 0.566,-0.361 0.833,-0.498c2.365,-1.211 5.345,-1.012 7.567,0.498l14.855,10.304l-5.209,-17.312c-0.171,-0.591 -0.168,-0.65 -0.216,-0.946c-0.421,-2.624 0.689,-5.396 2.812,-7.043l14.39,-10.944l-18.074,-0.396c-0.615,-0.02 -0.67,-0.04 -0.967,-0.087c-2.629,-0.41 -4.921,-2.329 -5.829,-4.851l-5.962,-17.067c-1.987,5.689 -3.975,11.378 -5.962,17.067Z"
      fillRule="nonzero"
    />
  </svg>
);

export default Stars;
